<template>
  <div class="constrain logo">
    <Go to="/#hero" class="logo" aria-label="Home">
      <logo class="dooh" />
    </Go>
  </div>

  <div id="nav">
    <div class="relative constrain flex items-center text-sm">
      <nav class="inner flex md:w-full">
        <transition-group name="default">
          <Go
            v-for="link of navigation"
            :key="link.id"
            :to="link.uri"
            class="px-4 link"
            v-text="link.title"
          />
        </transition-group>
      </nav>
      <div class="md:hidden w-full" />
      <Language v-if="false" class="mr-4 sm:mr-0" />
      <Hamburger
        class="flex-shrink-0 md:hidden"
        :class="{ active: mobile }"
        @click="toggle()"
      />
    </div>
  </div>

  <div class="movile-wrapper">
    <div class="backdrop" :class="{ active: mobile }" />
    <transition-group name="appear">
      <div v-if="mobile" class="mobile-nav">
        <Go
          v-for="link of navigation"
          :key="link.id"
          :to="link.uri"
          class="px-4 link"
          @click="toggle(true)"
          v-text="link.title"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import { ref } from 'vue';
import Scrolllock from '@/composables/scrolllock';
import Logo from '@/assets/images/logo.svg';
import Language from '../utils/Language.vue';
import Hamburger from '../utils/Hamburger.vue';

export default {
  components: {
    Logo,
    Language,
    Hamburger,
  },
  setup() {
    const { lock, unlock } = Scrolllock();
    const navigation = ref([
      { id: 'results', uri: '/gewinner', title: 'Gewinner' },
      { id: 'dooh-award', uri: '/#dooh-award', title: 'DOOH-Award' },
      { id: 'charity-award', uri: '/#charity-award', title: 'Charity Award' },
      { id: 'voting', uri: '/#voting', title: 'Voting' },
      { id: 'info', uri: '/#info', title: 'Info' },
    ]);

    return {
      lock,
      unlock,
      navigation,
      mobile: ref(false),
    };
  },

  methods: {
    toggle(close) {
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  position: relative;
  width: 100%;
  z-index: 101;

  a {
    display: flex;
    flex-direction: column;
  }
  .dooh {
    position: fixed;
    z-index: 1000;
    top: 30px;
    width: 260px;
  }

  @media (max-width: theme("screens.md")) {
    .dooh {
      top: 23px;
      width: 235px;
    }
  }
}

#nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  padding-top: 40px;
  pointer-events: none;
  background: linear-gradient(to bottom, black, transparent);
  padding-left: 300px;

  @media (min-width: theme("screens.xl")) {
    padding-left: 400px;
  }

  @media (min-width: 2180px) {
    padding-left: 600px;
  }

  @media (max-width: theme("screens.md")) {
    padding-top: 28px;
    padding-left: 274px;

    .inner {
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 0;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background: white;
    }
  }
}

.inner,
.mobile-nav,
:deep(.Language),
.hamburger {
  pointer-events: all;
}

.link {
  font-weight: 500;
  color: white;

  &.active {
    text-decoration: underline;
  }
}

/* MOBILE NAV */
.backdrop {
  content: "";
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: rgba(255, 255, 255, 0);
  transition: 0.5s ease;
  pointer-events: none;
  &.active {
    background: var(--color-violet-dark);
    backdrop-filter: blur(6px);
    pointer-events: all;
  }
}

.mobile-nav {
  position: fixed;
  overflow-y: scroll;

  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  font-size: 2rem;

  a {
    padding: 0.8rem 1rem;
    text-decoration: none !important;
  }
}
</style>
