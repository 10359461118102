<template>
  <span v-if="!src" />
  <span v-else-if="src.svg" :alt="src.alt" v-html="src.svg" />
  <img
    v-else
    ref="image"
    :alt="src.alt"
    loading="lazy"
  >
</template>

<script>

const breakpoints = {
  xl: '1900px',
  lg: '1500px',
  md: '920px',
  sm: '640px',
};

export default {
  props: {
    src: { type: Object, default: null },
    sizes: { type: Object, default: () => ({}) },
  },
  mounted() {
    if (!this.src || this.src.svg) return;

    this.$refs.image.src = this.src.normal;

    const srcset = [];
    for (let width = 100; width < 3000; width += Math.ceil(width * 0.16)) {
      srcset.push(`${this.src.large} ${width}w`);
    }
    this.$refs.image.srcset = srcset.join(', ');

    let sizes = '';
    if (this.sizes) {
    // eslint-disable-next-line no-restricted-syntax
      for (const breakpoint in breakpoints) {
      // eslint-disable-next-line no-continue
        if (!this.sizes[breakpoint]) continue;
        sizes += `(min-width: ${breakpoints[breakpoint]}) ${this.sizes[breakpoint]}, `;
      }
      sizes += this.sizes.min ? this.sizes.min : '95vw';
    } else sizes = '95vw';
    this.$refs.image.sizes = sizes;

    if (process.env.NODE_ENV === 'development' && this.$store.state.useCdn) {
      const check = () => {
        const loadedWidth = this.$refs.image.naturalWidth;
        const shownWidth = this.$refs.image.offsetWidth;
        console.log(loadedWidth, shownWidth);
        if (shownWidth > loadedWidth) this.$refs.image.style.outline = '3px dashed red';
        else if (shownWidth < loadedWidth * 0.85) this.$refs.image.style.outline = '3px dashed orange';
        else this.$refs.image.style.outline = '3px dashed green';
      };
      this.$refs.image.onload = check;
      setTimeout(check, 2000);
      window.addEventListener('resize', check);
    }
  },
};
</script>
