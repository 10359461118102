<template>
  <div class="hamburger">
    <div class="bar" />
    <div class="bar" />
    <div class="bar" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.hamburger {
  box-sizing: content-box;
  width: 35px;
  padding: 15px;
  margin: -15px;
}

.bar {
  height: 3px;
  background-color: white;
  border-radius: 5px;
  transition: all 0.5s;

  &:nth-child(1) {
    width: 100%;
    margin-bottom: 5px;
    .active & {
      transform: translateY(8px) rotate(45deg);
    }
  }

  &:nth-child(2) {
    width: 100%;
    transform: translateX(0);
    margin-bottom: 5px;
    .active & {
      opacity: 0;
      transform: translateX(20px) !important;
    }
  }

  &:nth-child(3) {
    width: 100%;
    .active & {
      transform: translateY(-9px) rotate(-45deg);
    }
  }

}
</style>
