/* eslint-disable */
import { useI18n } from 'vue-i18n';

export function useLanguageSwap() {
  const i18n = useI18n();

  return (locale) => {
    i18n.locale.value = locale;
  };
}
