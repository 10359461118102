<template>
  <footer class="Footer bg-violet-dark">
    <div class="constrain">
      <div class="flex flex-wrap sm:flex-nowrap justify-between">
        <div class="order-2 sm:order-1" style="width: 260px;">
          &copy; Clear Channel Schweiz
        </div>
        <div class="w-full order-1 sm:order-2 text-center links">
          <Go to="https://www.clearchannel.ch/de/impressum" class="link">
            Impressum
          </Go>
          <Go to="https://www.clearchannel.ch/de/datenschutz" class="link">
            Datenschutz
          </Go>
          <Go to="https://www.clearchannel.ch/de/agb" class="link">
            AGB
          </Go>
          <Go
            v-if="store.state.socials.twitter"
            :to="store.state.socials.twitter"
            class="link"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </Go>
          <Go
            v-if="store.state.socials.linkedin"
            :to="store.state.socials.linkedin"
            class="link"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'linkedin-in']" />
          </Go>
          <Go
            v-if="store.state.socials.youtube"
            :to="store.state.socials.youtube"
            class="link"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'youtube']" />
          </Go>
          <Go
            v-if="store.state.socials.xing"
            :to="store.state.socials.xing"
            class="link"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'xing']" />
          </Go>
          <Go
            v-if="store.state.socials.facebook"
            :to="store.state.socials.facebook"
            class="link"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'facebook-f']" />
          </Go>
          <Go
            v-if="store.state.socials.instagram"
            :to="store.state.socials.instagram"
            class="link"
            target="_blank"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </Go>
        </div>
        <div class="text-right order-3" style="width: 260px;">
          <!--
          <template v-for="(l, index) in availableLocales" :key="l">
            <a
              href="#"
              :class="{
                'mr-4': index < availableLocales.length - 1,
                'text-white': l === loc,
                'text-violet': l !== loc,
              }"
              @click.prevent="swap(l)"
            >
              {{ l.toUpperCase() }}
            </a>
          </template>
          -->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useLanguageSwap } from '@/composables/language';

export default {
  name: 'Footer',
  setup() {
    const store = useStore();
    const {
      availableLocales,
      locale,
    } = useI18n();
    const loc = ref(locale);

    return {
      locale,
      loc,
      availableLocales,
      swap: useLanguageSwap(),
      store,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

.Footer {
  @apply text-sm;
  --color-active: var(--color-red);
  padding-top: 15px;
  padding-bottom: 15px;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  @screen sm {
    justify-content: center;
    margin-bottom: 0;
  }

  .link {
    display: block;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:nth-child(3) {
      margin-right: auto;
    }

    @screen sm {
      &:nth-child(3) {
        margin-right: 1rem;
      }
    }

    &:hover {
      color: var(--color-violet);
    }
  }
}
</style>
