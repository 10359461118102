/* eslint-disable max-len */
export default {
  hero: {
    slogan: 'Die Schweizer Auszeichnung für die besten digitalen Plakatkampagnen.',
  },
  intro: {
    text: '<p>Helfen Sie uns die Shortlist für die Jury zu erstellen und voten Sie für Ihre 5 Favoriten in der Kategorie Digital Out of Home Award und Ihre 3 Favoriten in der Kategorie Charity Award!</p><p>Das Voting endet am 22. Oktober 2021.</p>',
  },
  vote: {
    contact: 'Bei Fragen können Sie uns gerne kontaktieren:',
    formText: '<p>Haben Sie für beide Kampagnen Ihren Favoriten ausgewählt?</p><p>Nur die besten Kampagnen schaffen es auf die Shortlist. Helfen Sie uns die diesjährigen Gewinner des Digital Out of Home Awards und des Charity Awards zu finden.</p><p>Bei Fragen können Sie uns gerne kontaktieren:<br><a href="mailto:marketing{\'@\'}clearchannel.ch" target="_blank">marketing{\'@\'}clearchannel.ch</a><br>Tel. <a href="tel:0041584555500" target="_blank">+41 (0)58 455 55 00</a></p>',
    noneSelected: 'Du hast bisher keine Kampagnen favorisiert.',
    success: 'Vielen Dank für Ihre Stimme!',
  },
  bottomText: {
    heading: 'Mit dem Digital Out-of-Home Award will Clear Channel Kampagnen auszeichnen, die das Medium DOOH innovativ und gezielt einsetzen.',
    text: '<p>Während des Jahres werden 450 Kampagnen durch Clear Channel gesichtet. Die besten landen hier und Sie können mitentscheiden, welche Kampagnen es auf die Shortlist schaffen. Über den Gewinner entscheidet die Jury nach folgenden Kriterien:</p><p><ol class="mb-4"><li><strong>Botschaft</strong> – kurz, knapp, klar</li><li><strong>Technologie und Nutzung Medium</strong> – optimal eingesetzt</li><li><strong>Kreativität/Idee</strong> – überraschen und emotional</li></ol><p>Bei Fragen können Sie uns gerne kontaktieren:<br><a href="mailto:marketing{\'@\'}clearchannel.ch" target="_blank">marketing{\'@\'}clearchannel.ch</a><br>Tel. <a href="tel:0041584555500" target="_blank">(0)58 455 55 00</a></p>',
  },
};
