<template>

  <div id="overlay" />

  <Navigation />

  <main>
    <router-view />
  </main>

  <Footer />
</template>

<script>
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head';
import { useI18n } from 'vue-i18n';
import Navigation from '@/components/structure/Navigation.vue';
import Footer from '@/components/structure/Footer.vue';

export default {
  components: {
    Navigation,
    Footer,
  },

  setup() {
    const { locale } = useI18n();
    useHead({
      title: 'DOOH - Clear Channel',
      htmlAttrs: {
        lang: locale,
      },
    });

    const store = useStore();
    store.dispatch('FETCH_SOCIALS');
  },
};
</script>
