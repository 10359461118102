<template>
  <div class="Language">
    <transition-group tag="ul" name="list" class="inner">
      <li
        v-for="locale in sortedLocales"
        :key="locale"
        class="lang"
      >
        <a
          href="#"
          class="text-white"
          @click.prevent="swap(locale)"
        >{{ locale }}</a>
      </li>
    </transition-group>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useLanguageSwap } from '@/composables/language';

export default {
  setup() {
    const { locale, availableLocales } = useI18n();

    return {
      availableLocales,
      swap: useLanguageSwap(),
      sortedLocales: computed(() => {
        const locales = [...availableLocales];
        const idx = availableLocales.findIndex((l) => l === locale.value);
        return [...locales.splice(idx, 1), ...locales];
      }),
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";

.Language {
  position: relative;
  width: 50px;
  height: 35px;
  margin-top: 7px;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 500;
  white-space: nowrap;

  .inner {
    position: absolute;
    z-index: 1000;
    width: 45px;
    border: 1px solid transparent;
    border-radius: 99px;
    padding: 0 8px;
    overflow: hidden;
    transition: 0.25s ease;

    li {
      display: inline-block;
      &:first-child a {
        opacity: 1;
        pointer-events: none;
      }
    }
    a {
      opacity: 0;
      padding: 4px 8px;
      display: inline-block;

      &:hover {
        color: var(--color-active);
      }
    }
  }

  &:hover {
    .inner {
      border-color: white;
      width: 85px;
    }
    a {
      opacity: 1;
    }
  }

  @media (max-width: theme("screens.md")) {
    font-size: 0.8rem;
    margin-top: 0px;

    .inner {
      padding: 0 4px;
    }

    &:hover {
      .inner {
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(6px);
        width: 92px;
      }
    }
  }
}

.list-move {
  transition: transform 0.3s cubic-bezier(0.59, 0.12, 0.34, 0.95);
}
</style>
