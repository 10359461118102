/* eslint-disable max-len */
import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import VueScrollTo from 'vue-scrollto';
import smoothscroll from 'smoothscroll-polyfill';
import { createRouter, createWebHistory } from 'vue-router';
import { createI18n } from 'vue-i18n';
import { createGtm } from '@gtm-support/vue-gtm';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faXing } from '@fortawesome/free-brands-svg-icons/faXing';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';
import Image from '@/components/utils/Image.vue';
import messagesDe from '@/translations/de';
import messagesEn from '@/translations/en';

import store from './store';
import checkView from './vue-check-view';

import './assets/styles/main.scss';

// polyfills
// --------------------------------

smoothscroll.polyfill();

// app
// --------------------------------
const app = createApp(App);

const head = createHead();

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: () => import(/* webpackChunkName: "landingPage" */ './components/structure/LandingPage.vue') },
    { path: '/gewinner', component: () => import(/* webpackChunkName: "results" */ './components/structure/Results.vue') },
    { path: '/:locale/gewinner/kampagnen/:title', component: () => import(/* webpackChunkName: "landingPage" */ './components/structure/Results.vue') },
    { path: '/:locale/gewinner/resultate/:title', component: () => import(/* webpackChunkName: "landingPage" */ './components/structure/Results.vue') },
    { path: '/:locale/kampagnen/:title', component: () => import(/* webpackChunkName: "landingPage" */ './components/structure/LandingPage.vue') },
    // 404
    { path: '/:pathMatch(.*)*', components: () => import(/* webpackChunkName: "404" */ './components/structure/NotFound.vue') },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return { el: to.hash, behavior: 'smooth' };
    if (savedPosition) return savedPosition;
    return { top: 0, behavior: 'smooth' };
  },
});

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
    de: messagesDe,
    en: messagesEn,
  },
  legacy: false,
});

library.add(
  faTwitter,
  faXing,
  faLinkedinIn,
  faYoutube,
  faFacebookF,
  faInstagram,
);

app.use(head);
app.use(router);
app.use(store);
app.use(i18n);
app.use(VueScrollTo);
app.use(checkView, { insideThreshold: () => (window.innerWidth < 960 ? 0.1 : 0.15) });
app.use(createGtm({
  id: 'GTM-K53X97B',
  enabled: true,
  debug: true,
}));
app.component('FontAwesomeIcon', FontAwesomeIcon);

window.getPath = () => window.location.pathname.replace(/\/$/, '');
window[atob('amt3ZWI=')] = atob('Tm8sIHRoaXMgaXMgTmljayE=');

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);

app.mount('#app');
if (!window.prerender) setTimeout(() => window.document.body.classList.remove('no-anim'), 100);
