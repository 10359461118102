import { createStore, createLogger } from 'vuex';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  state: () => ({
    api: 'https://admin.dooh-award.ch/de',
    useCdn: false,
    socials: {},
    campaigns: [],
    results: [],
    charityCampaigns: [],
    favoriteCampaigns: [],
    favoriteCharityCampaigns: [],
  }),
  mutations: {
    SET_CAMPAIGNS(state, e) {
      state.campaigns = e;
    },
    SET_RESULTS(state, e) {
      state.results = e;
    },
    SET_CHARITY(state, e) {
      state.charityCampaigns = e;
    },
    SET_SOCIALS(state, e) {
      state.socials = e;
    },
    TOGGLE_FAVORITE(state, e) {
      if (e.charity.length) {
        if (state.favoriteCharityCampaigns.length > 4) {
          return;
        }
        const idx = state.favoriteCharityCampaigns.findIndex((c) => c.id === e.id);
        if (idx < 0 && state.favoriteCharityCampaigns.length < 3) {
          state.favoriteCharityCampaigns.push(e);
        } else if (idx > -1) {
          state.favoriteCharityCampaigns.splice(idx, 1);
        }
      } else {
        const idx = state.favoriteCampaigns.findIndex((c) => c.id === e.id);
        if (idx < 0 && state.favoriteCampaigns.length < 5) {
          state.favoriteCampaigns.push(e);
        } else if (idx > -1) {
          state.favoriteCampaigns.splice(idx, 1);
        }
      }
    },
  },
  actions: {
    async FETCH_CAMPAIGNS({
      state,
      commit,
    }) {
      const request = await fetch(`${state.api}/kampagnen`);
      const json = await request.json();
      const [campaignsComponent] = json.content;
      const campaigns = campaignsComponent.data.campaigns.map((c) => c.data);
      const charity = campaignsComponent.data.charity.map((c) => c.data);
      commit('SET_CAMPAIGNS', campaigns);
      commit('SET_CHARITY', charity);
    },
    async FETCH_SOCIALS({ state, commit }) {
      const request = await fetch(`${state.api}`);
      const json = await request.json();
      commit('SET_SOCIALS', json.content[0].data);
    },
    async FETCH_RESULTS({
      state,
      commit,
    }) {
      const request = await fetch(`${state.api}/resultate`);
      const json = await request.json();
      const [resultsComponent] = json.content;
      const results = resultsComponent.data.results.map((c) => c.data);
      commit('SET_RESULTS', results);
    },
  },
});
